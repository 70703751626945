<template>
  <AppLayout wrapperClass="payment-success">
    <Breadcrumbs class="my-30" />

    <section v-if="order !== null" class="pt-25">
      <b-container>
        <div>
          <h1 class="title">{{ $t("payment_success") }}</h1>
          <div class="order-number" v-if="order.increment_id">
            {{ $t("order_number") }}: #{{ order.increment_id }}<br>
          </div>
          <a href="/" class="btn btn-warning font-weight-bold rounded px-md-100 my-25">
            {{ $t("back_to_homepage") }}
          </a>
        </div>

        <!-- Customer Information -->
        <!-- <div class="mb-4">
          <h4>{{ $t("customer_information") }}</h4>
          <div class="row">
            <div class="col-md-6">
              <h5>{{ $t("billing_address") }}</h5>
              <p>
                {{ order.billing_address.firstname }} {{ order.billing_address.lastname }}<br>
                {{ order.billing_address.street.join(', ') }}<br>
                {{ order.billing_address.postcode }} {{ order.billing_address.city }}<br>
                {{ order.billing_address.country_code }}<br>
                {{ $t("phone") }}: {{ order.billing_address.telephone }}
              </p>
            </div>
            <div class="col-md-6">
              <h5>{{ $t("shipping_address") }}</h5>
              <p>
                {{ order.shipping_address.firstname }} {{ order.shipping_address.lastname }}<br>
                {{ order.shipping_address.street.join(', ') }}<br>
                {{ order.shipping_address.postcode }} {{ order.shipping_address.city }}<br>
                {{ order.shipping_address.country_code }}<br>
                {{ $t("phone") }}: {{ order.shipping_address.telephone }}
              </p>
            </div>
          </div>
        </div> -->

        <!-- Shipping & Payment -->
        <!-- <div class="mb-4">
          <div class="row">
            <div class="col-md-6">
              <h5>{{ $t("shipping_method") }}</h5>
              <p>{{ order.shipping_method }} ({{ order.carrier }})</p>
            </div>
            <div class="col-md-6">
              <h5>{{ $t("payment_method") }}</h5>
              <p>{{ order.payment_methods[0].name }}</p>
            </div>
          </div>
        </div> -->

        <div class="mt-20">
          <h3 class="order-overview-title"> {{ $t("your_order_overview") }} </h3>
        </div>

        <!-- Order Items -->
        <div>
          <b-row v-for="orderItem of order.items" :key="orderItem.product_sku" class="order-item-row position-relative">
            <b-col md="8" class="d-flex">
              <a class="product-image border border-info">
                <b-img :src="orderItem.image.medium" />
              </a>
              <div class="product">
                <b-link :to="'/' + orderItem.product_url_key">
                  <h4 class="product--name">{{ orderItem.product_name }}</h4>
                </b-link>
                <!-- <div>
                    <span><strong>{{ $t("product_number") }}:</strong> {{ orderItem.product_sku }}</span>
                  </div> -->
              </div>
            </b-col>

            <b-col md="2">
              <span class="quantity"><strong>{{ $t("quantity") }}:</strong> {{ orderItem.quantity_ordered }}</span>
            </b-col>

            <b-col md="2">
              <span class="product--price text-danger">
                {{ formatCurrency(orderItem.product_sale_price.value) }}
              </span>
            </b-col>
          </b-row>
        </div>

        <!-- Order Totals -->
        <b-row class="discount-and-total">
          <b-col md="6" xl="5" class="cost">
            <span class="cost-heading d-block">{{ $t("totals") }}</span>

            <ul class="prices">
              <li class="d-flex pl-0 justify-content-between">
                <span class="d-block mr-20">{{ $t("subtotal") }}</span>
                <span class="cost d-block">{{ formatCurrency(order.total.subtotal_incl_tax.value) }}</span>
              </li>

              <li class="d-flex pl-0 justify-content-between">
                <span class="name">{{ $t("shipping_cost") }}</span>
                <span class="cost ml-10">+ {{ formatCurrency(order.total.shipping_handling.amount_including_tax.value) }}</span>
              </li>

              <li class="d-flex pl-0 justify-content-between">
                <span class="name">{{ $t("tax") }}</span>
                <span class="cost ml-10"> {{ formatCurrency(order.total.total_tax.value) }}</span>
              </li>
            </ul>

            <div class="d-flex justify-content-between align-items-center mt-15 mb-25">
              <span class="total">{{ $t("total") }}:</span>
              <span class="total-price ml-10 text-danger">{{ formatCurrency(order.total.grand_total.value) }}</span>
            </div>
          </b-col>
          <b-col md="5" lg="4" class="coupon-discount">
          </b-col>
        </b-row>
      </b-container>
    </section>

    <div class="container">
      <section class="more-products product-listing" v-if="crossSellProducts && crossSellProducts.length > 0">
        <span class="section-title d-block font-weight-normal text-center">Etwas vergessen? Bestellen Sie es hier</span>
        <MoreProductsCarousel class="pt-5" :products="crossSellProducts" />
      </section>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";
import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import { getOrderInfoFromHash, getCustomerCartToken } from "@storefront/core/data-resolver/cart";
import config from "@storefront/core/lib/utils/config";
import MoreProductsCarousel from "@/esf_kerkrade_vitanatura/core/components/MoreProductsCarousel";
// import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "PaymentSuccess",
  data() {
    return {
      order: null,
      crossSellProducts: null,
    };
  },
  components: {
    AppLayout,
    MoreProductsCarousel,
    Breadcrumbs
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("payment_success") },
        { name: "keywords", content: this.$t("payment_success") },
        { name: "description", content: this.$t("payment_success") },
      ],
      title: this.$t("payment_success"),
    };
  },
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
  },
  methods: {
    formatCurrency(value) {
      return this.$helpers.formatCurrency(value);
    },
    async createCartToken(){
      const newCartToken = await getCustomerCartToken();
      this.$store.commit("cart/setServerToken", newCartToken);
    }
  },
  async mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);

    const hashId = localStorage.getItem("orderHash");

    if (hashId) {
      const order = await getOrderInfoFromHash(hashId);
      this.order = order;

      if (order) {
        this.orderId = order.number;
        if (this.$gtm.enabled() == true) {
          const items = [];
          const prods = [];
          order.items.forEach((element) => {
            let item = {};
            let prod = {};
            item.id = element.product_sku;
            item.name = element.product_name;
            item.price = element.product_sale_price;
            item.quantity = element.quantity_ordered;
            prod.item_id = element.product_sku;
            prod.item_name = element.product_name;
            prod.price = element.product_sale_price;
            prod.quantity = element.quantity_ordered;
            items.push(item);
            prods.push(prod);
          });
          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                currencyCode: "EUR",
                purchase: {
                  actionField: {
                    id: order.number,
                    affiliation: config.app_name,
                    revenue: order.total.subtotal.value,
                    tax: order.total.total_tax.value,
                    shipping: order.total.total_shipping.value,
                  },
                  products: items,
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                add: {
                  transaction_id: order.number,
                  affiliation: config.app_name,
                  value: order.total.subtotal.value,
                  tax: order.total.total_tax.value,
                  shipping: order.total.total_shipping.value,
                  items: prods,
                },
              },
            });
          }
        }

        if (config.helloRetail && config.helloRetail.enabled) {
          const hritems = [];
          order.items.forEach((element) => {
            let item = {};
            item.id = element.product_sku;
            hritems.push(item);
          });

          self.ADDWISH_PARTNER_NS.api.conversion.track_sale(
            {
              total: order.total.subtotal.value,
              orderNumber: order.number,
              productNumbers: hritems,
            },
            function () {
              // Conversion has been tracked for HR
            }
          );
        }
        if(this.$store.getters['user/getIsLoggedIn']){
          this.createCartToken();
        }
      }
    }

    var skuList = [];
    this.order.items?.forEach((item) => {
      skuList.push(item.product_sku);
    });

    await this.$store.dispatch("product_local/loadCrossSellSkuArray", {
      skus: skuList,
    });

    setTimeout(() => {
      this.crossSellProducts = this.$store.getters["product_local/getCrossSellSkuArray"];
      localStorage.removeItem('checkoutPayment');
      localStorage.removeItem('orderHash');
      console.log("getCrossSellProducts", this.crossSellProducts)
    }, 1000)
  }
};
</script>
